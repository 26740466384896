import {
  ImageStyleAvailable,
  ImageStyleDerivative,
} from "@/graphql/sdk/__generated__";

export function getVariation(
  variations: ImageStyleDerivative[],
  variationName: ImageStyleAvailable,
) {
  return variations ? variations.find((variation) => variation.name === variationName) : [];
}
